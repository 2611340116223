@import "./settings";

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #DAF386;
}

/* Styles pour le corps de la page */
body {
    font-family: Lato;
    margin: 0;
    padding: 0;
    line-height: 0.9;
    height: 100%;
}

/* Styles pour l'en-tête (header) */
header {
    background-color: #DAF386;

}

nav {
    width: 100%
}

.navbar-content {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}

/* Styles pour la barre de navigation */
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px;
    margin: 0px;
    min-height: 50px;
    list-style-type: none;
}

/* Styles pour les liens de navigation */
.navbar a {
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    margin: 0 5px;
    /* Marge horizontale entre les liens */
}

/* Styles pour la section principale */
main {
    width: 100%;
    background-color: #d4dac1;
    flex: 1;
}

.main-content {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}

.description {
    font-size: 16px;
    line-height: 1.5;
    font-family: "Arial", sans-serif;
    text-align: justify;
    letter-spacing: 1px;
}

.contact {
    padding-top: 20px;
    text-align: center;
}

.map {
    text-align: right;
    width: 200px;
    height: 200px;
}

/* Styles pour le pied de page (footer) */
footer {
    background-color: #DAF386;
    min-height: 50px;
    margin-top: auto;
    width: 100%;
    padding: 20px;
    text-align: center
}

;

.div-container {
    display: flex;
    /* Utilisez un conteneur flex pour disposer les div côte à côte */
}

.div-align {
    flex: 1;
    padding: 10px;
}

ul {
    /* Pour supprimer les puces par défaut */
    list-style-type: none;
    margin: 0;
    /* Pour supprimer le padding par défaut de la liste */
    padding: 0;
    overflow: hidden;
}

li {
    margin-bottom: 0px;
    /* Réglez cette valeur selon vos préférences pour réduire l'espace entre les éléments de la liste */
}

#content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#icone-airbnb {
    width: 20px;
    height: 20px;
    padding-left: 5px;
}