.banner {
    width: 100%;
    /* La bannière occupera la largeur de son conteneur */
    max-width: 100%;
    /* La largeur maximale de la bannière sera égale à la largeur du conteneur */
    height: auto;
    /* La hauteur s'ajustera automatiquement pour conserver les proportions de l'image */
}

/* Ajoutez d'autres styles spécifiques à votre bannière ici */

@media (max-width: 768px) {

    /* Styles spécifiques pour les écrans de 768 pixels de large ou moins (mobile) */
    .banner {
        /* Vous pouvez définir ici des styles spécifiques pour la version mobile de la bannière */
    }
}