h2 {
    text-align: center;
}

/* Styles généraux pour le formulaire */
.contact-form {
    max-width: 400px;
    padding-top: 20px;
    margin: 0 auto;
}

/* Style pour les étiquettes des champs */
.contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

/* Style pour les champs de texte et d'email */
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style pour le champ de message textarea */
.contact-form textarea {
    height: 150px;
}

/* Style pour le bouton d'envoi */
.contact-form button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Style pour le bouton d'envoi au survol */
.contact-form button:hover {
    background-color: #0056b3;
}

/* Style pour les messages de validation ou d'erreur */
.contact-form .success-message {
    color: #009900;
    font-weight: bold;
}

.contact-form .error-message {
    color: #FF0000;
    font-weight: bold;
}